import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
/*import AttachMoneyIcon from '@mui/icons-material/AttachMoney';*/
import axios from 'axios';
import config from '../../../config/config.js'

const MainContent = ({raceName}) => {
  const [totalRows, setTotalRows] = useState(0);
  const [totalInscriptos, setTotalInscriptos] = useState(0);
  /*const [recaudacion, setRecaudacion] = useState(0);*/
  const [inscriptosPorDistancia, setInscriptosPorDistancia] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!raceName) return;

      try {
        const response = await axios.get(`${config.INSCRIPTION_URL}/api/metrics/${raceName}`);
  
        // Validar y asignar datos de la respuesta
        setTotalRows(response.data.totalRows || 0);
        setTotalInscriptos(response.data.totalInscriptos || 0);
        /*setRecaudacion(response.data.recaudacion || 0);*/
        setInscriptosPorDistancia(response.data.inscriptosPorDistancia || []);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
        // Asignar valores por defecto en caso de error
        setTotalRows(0);
        setTotalInscriptos(0);
        /*setRecaudacion(0);*/
        setInscriptosPorDistancia([]);
      }
    };
  
    fetchData();
  }, [raceName]);

  return (
    <div className='container'>
      <div className="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6" style={{ marginTop: '60px' }}>
        <Card className="bg-white shadow-lg">
          <CardContent>
            <Typography variant="h5">Inscriptos</Typography>
            <Typography variant="h5" className="text-blue-500" sx={{fontSize: '0.875rem'}}>Total</Typography>
            <GroupsIcon />
            <Typography variant="h2" className="text-blue-500">{totalRows}</Typography>
          </CardContent>
        </Card>

        <Card className="bg-white shadow-lg">
          <CardContent>
            <Typography variant="h5">Inscriptos</Typography>
            <Typography variant="h5" className="text-green-500" sx={{fontSize: '0.875rem'}}>Confirmados</Typography>
            <GroupsIcon />
            <Typography variant="h2" className="text-green-500">{totalInscriptos}</Typography>
          </CardContent>
        </Card>

        {/*<Card className="bg-white shadow-lg">
          <CardContent>
            <Typography variant="h5">Recaudación</Typography>
            <AttachMoneyIcon />
            <Typography variant="h4" className="text-blue-500">${recaudacion}</Typography>
          </CardContent>
        </Card>*/}

        <Card className="bg-white shadow-lg">
          <CardContent>
            <Typography variant="h5">Inscriptos por distancia</Typography>
            {inscriptosPorDistancia.map((distancia) => (
              <Typography key={distancia.distance} variant="h6" className="text-orange-500">
                {distancia.distance}: {distancia.count}
              </Typography>
            ))}
          </CardContent>
        </Card>
      </div>

    </div>
  );
};

export default MainContent;
