import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Typography, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import axios from 'axios';
import config from '../../../config/config.js';

const InscriptionPeriod = ({ raceName }) => {
  const [periodName, setPeriodName] = useState([]);
  const [distance, setDistance] = useState('');
  const [price, setPrice] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [message, setMessage] = useState('');
  const [periods, setPeriods] = useState([]);
  const [editingPeriod, setEditingPeriod] = useState(null);

  const fetchPeriods = useCallback(async () => {
    try {
      const response = await axios.get(`${config.INSCRIPTION_URL}/api/inscription-periods`, { params: { raceName } });
      setPeriods(response.data.periods);
    } catch (error) {
      console.error('Error al obtener los períodos de inscripción:', error);
    }
  }, [raceName]); // Depende de raceName

  useEffect(() => {
    fetchPeriods();
  }, [fetchPeriods]); // Ahora está seguro

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!periodName || !distance || !price || !startDate || !endDate) {
      setMessage('Todos los campos son obligatorios.');
      return;
    }

    try {
      if (editingPeriod) {
        // Edit existing period
        await axios.put(`${config.INSCRIPTION_URL}/api/inscription-periods/${editingPeriod.id}`, {
          raceName,
          periodName,
          distance,
          price: parseFloat(price),
          startDate,
          endDate,
        });
        alert('Período de inscripción actualizado con éxito.');
      } else {
        // Create new period
        await axios.post(`${config.INSCRIPTION_URL}/api/inscription-periods`, {
          raceName,
          periodName,
          distance,
          price: parseFloat(price),
          startDate,
          endDate,
        });
        alert('Período de inscripción creado con éxito.');
      }

      setPeriodName('');
      setDistance('');
      setPrice('');
      setStartDate('');
      setEndDate('');
      setEditingPeriod(null);
      fetchPeriods();
    } catch (error) {
      console.error('Error al guardar el período de inscripción:', error);
      setMessage('Hubo un error al guardar el período de inscripción.');
    }
  };

  const handleEdit = (period) => {
    setEditingPeriod(period);
    setPeriodName(period.periodName);
    setDistance(period.distance);
    setPrice(period.price);
    setStartDate(period.startDate.split('T')[0]); // Format date for input
    setEndDate(period.endDate.split('T')[0]); // Format date for input
  };

  return (
    <Grid container spacing={4} sx={{maxWidth: '1000px'}}>
      {/* Formulario de creación/edición */}
      <Grid item xs={12} md={4} sx={{padding: '30px', marginTop: '110px', marginLeft: '60px', boxShadow: '0px 1px 10px black'}}>
        <Box>
          <Typography variant="h5" gutterBottom>
            {editingPeriod ? 'Editar Período de Inscripción' : 'Crear Período de Inscripción'}
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Nombre del Período"
              value={periodName}
              onChange={(e) => setPeriodName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Distancia"
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Precio"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Fecha de Inicio"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Fecha de Finalización"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <Button type="submit" variant="contained" color="error" fullWidth>
              {editingPeriod ? 'Actualizar Período' : 'Crear Período'}
            </Button>
          </form>
          {message && (
            <Typography variant="body1" color="error" marginTop="16px">
              {message}
            </Typography>
          )}
        </Box>
      </Grid>

      {/* Lista de períodos */}
      <Grid item xs={12} md={7} sx={{padding: '30px', marginTop: '110px', marginLeft: '20px', boxShadow: '0px 1px 10px black'}}>
        <Box>
          <Typography variant="h5" gutterBottom>
            Períodos de Inscripción
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Distancia</TableCell>
                  <TableCell>Precio</TableCell>
                  <TableCell>Fechas</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {periods.map((period) => (
                  <TableRow key={period.id}>
                    <TableCell>{period.periodName}</TableCell>
                    <TableCell>{period.distance}</TableCell>
                    <TableCell><td>{Number(period.price).toFixed(2)}</td></TableCell>
                    <TableCell>
                      {period.startDate.split('T')[0]} - {period.endDate.split('T')[0]}
                    </TableCell>
                    <TableCell>
                      <IconButton color="error" onClick={() => handleEdit(period)}>
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default InscriptionPeriod;
