import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Typography, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl, InputLabel, Select, MenuItem, } from '@mui/material';
import axios from 'axios';
import config from '../../../config/config.js';

const DiscountCode = ({ raceName }) => {
  const [code, setCode] = useState('');
  const [discountType, setDiscountType] = useState('percentage'); // Nuevo estado para tipo de descuento
  const [discountValue, setDiscountValue] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [creador, setCreador] = useState('');
  const [cantidad, setCantidad] = useState('');
  const [message, setMessage] = useState('');
  const [discountCodes, setDiscountCodes] = useState([]);

  // Función para obtener los códigos de descuento
  const fetchDiscountCodes = useCallback(async () => {
    try {
      const response = await axios.get(`${config.INSCRIPTION_URL}/api/discounts/list`, {
        params: { raceName },
      });
      setDiscountCodes(response.data.codes);
    } catch (error) {
      console.error('Error al obtener los códigos de descuento:', error);
    }
  }, [raceName]);

  useEffect(() => {
    fetchDiscountCodes();
  }, [fetchDiscountCodes]);

  // Función para crear un nuevo código de descuento
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!code || !discountValue || !expirationDate || !cantidad) {
      setMessage('Todos los campos son obligatorios');
      return;
    }

    try {
      const response = await axios.post(`${config.INSCRIPTION_URL}/api/discounts`, {
        raceName,
        code,
        discountType,
        discountValue: parseFloat(discountValue),
        expirationDate,
        creador,
        cantidad: parseInt(cantidad, 10), //para enviarla como numero entero
      });

      alert(response.data.message);
      setCode('');
      setDiscountType('percentage');
      setDiscountValue('');
      setExpirationDate('');
      setCreador('');
      setCantidad('');
      fetchDiscountCodes();
    } catch (error) {
      console.error('Error al crear el código de descuento:', error);
      alert('Hubo un error al crear el código de descuento.');
    }
  };

  return (
    <Grid container spacing={4} sx={{maxWidth: '1000px'}}>
      {/* Formulario para crear códigos de descuento */}
      <Grid item xs={12} md={4} sx={{padding: '30px', marginTop: '110px', marginLeft: '60px', boxShadow: '0px 1px 10px black'}}>
        <Box>
          <Typography variant="h5" gutterBottom>
            Crear Código de Descuento
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Código"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="discount-type-label">Tipo de Descuento</InputLabel>
              <Select
                labelId="discount-type-label"
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
              >
                <MenuItem value="percentage">Porcentaje (%)</MenuItem>
                <MenuItem value="amount">Monto Fijo</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label={
                discountType === 'percentage'
                  ? 'Porcentaje de Descuento (%)'
                  : 'Monto de Descuento ($)'
              }
              type="number"
              value={discountValue}
              onChange={(e) => setDiscountValue(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Cantidad"
              type="number"
              value={cantidad}
              onChange={(e) => setCantidad(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Fecha de Expiración"
              type="date"
              value={expirationDate}
              onChange={(e) => setExpirationDate(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Creador"
              value={creador}
              onChange={(e) => setCreador(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button type="submit" variant="contained" color="error" fullWidth>
              Crear Código
            </Button>
          </form>
          {message && (
            <Typography variant="body1" color="error" marginTop="16px">
              {message}
            </Typography>
          )}
        </Box>
      </Grid>

      {/* Lista de códigos de descuento */}
      <Grid item xs={12} md={7} sx={{padding: '30px', marginTop: '110px', marginLeft: '20px', boxShadow: '0px 1px 10px black'}}>
        <Box>
          <Typography variant="h5" gutterBottom>
            Códigos de Descuento
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Cantidad</TableCell>
                  <TableCell>Usados</TableCell>
                  <TableCell>Expiración</TableCell>
                  <TableCell>Creador</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {discountCodes.map((code) => (
                  <TableRow key={code.id}>
                    <TableCell>{code.code}</TableCell>
                    <TableCell>
                      {code.discountType === 'percentage' ? 'Porcentaje (%)' : 'Monto Fijo'}
                    </TableCell>
                    <TableCell>
                      {code.discountType === 'percentage'
                        ? `${code.discountValue}%`
                        : `$${code.discountValue.toFixed(2)}`}
                    </TableCell>
                    <TableCell>{code.cantidad}</TableCell>
                    <TableCell>{code.usosActuales}</TableCell>
                    <TableCell>{new Date(code.expirationDate).toLocaleDateString()}</TableCell>
                    <TableCell>{code.creador}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DiscountCode;