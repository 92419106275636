import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, Grid, Select, MenuItem, InputLabel, FormControl, Button } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { v4 as uuidv4 } from 'uuid';
import config from '../../config/config.js'
import talles from '../Inscriptions/Info/DesafioPuntaNegra2025/tallesPuntaNegra.png'

const Forms = () => {
  const { raceName } = useParams();
  const { state } = useLocation();
  const { distance, price, periodoInscripcion, priceTax, image } = state || {};
  const formattedRaceName = raceName.toLowerCase().replace(/\s+/g, '_');
  const [gender, setGender] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [preferenceId] = useState(null);
  const [email, setEmail] = useState('');
  const [shirtSize, setShirtSize] = useState('');
  const [paymentOption, setPaymentOption] = useState("100%");
  const [Procedencia, setProcedencia] = useState('');
  const [shoeSize, setShoeSize] = useState('');
  const [message, setMessage] = useState('');
  
  // Inicializa Mercado Pago solo cuando el componente se monta
  useEffect(() => {
    initMercadoPago('APP_USR-72879720-3f27-4cb1-b4fd-38f4ba5edaea', { locale: 'es-AR' });
  }, []); // El array vacío asegura que se ejecuta solo una vez

  const createPreference = async (formId) => {
    try {
      const priceToPay = calculatePrice();
      const payerEmail = email; // Usa el email capturado del estado
      const applicationFee = price * 0.03;
      const sellerAmount = priceToPay - applicationFee;
    
      /*const response = await axios.post('http://localhost:5000/create_preference', { */
      const response = await axios.post(`${config.INSCRIPTION_URL}/create_preference`, {
        title: `Inscripción - ${distance} - ${periodoInscripcion} - ${raceName}`,
        quantity: 1,
        price: priceToPay,
        formId,
        payerEmail,      // Añadir el email del pagador
        applicationFee,  // Añadir la comisión del marketplace
        sellerAmount,
        raceName: formattedRaceName,
      });
      /*console.log('Respuesta de Mercado Pago:', response.data);*/
      const { initPoint } = response.data;
    return initPoint;
    } catch (error) {
      console.error('Error al crear la preferencia:', error);
      alert("Hubo un error al procesar el pago. Intenta nuevamente.");
      return null;
    }
  };

  const handleDiscountCodeChange = (event) => {
    setDiscountCode(event.target.value);
  };
/** */
const applyDiscount = async () => {
  if (!discountCode) {
    alert("Por favor, ingresa un código de descuento.");
    return;
  }

  try {
    // Llamada al backend para validar el código
    const response = await axios.get(`${config.INSCRIPTION_URL}/api/discounts`, {
      params: { raceName: formattedRaceName, code: discountCode },
    });

    const { discountType, discountValue, expirationDate, usageCount, cantidad } = response.data;

    // Validar que el código no haya expirado
    const today = new Date();
    if (new Date(expirationDate) < today) {
      alert("El código de descuento ha expirado.");
      return;
    }

    // Validar si el código alcanzó su límite de usos
    if (usageCount >= cantidad) {
      alert("El código de descuento ya no está disponible.");
      return;
    }

    // Calcular el precio con descuento
    let newPrice = price; // Precio inicial

    if (discountType === "percentage") {
      const discountFactor = 1 - discountValue / 100; // Convierte el porcentaje en un factor de descuento
      newPrice = price * discountFactor;
    } else if (discountType === "amount") {
      newPrice = price - discountValue; // Resta el monto fijo
    }

    // Asegurarse de que el precio no sea negativo
    if (newPrice < 0) {
      newPrice = 0;
    }

    setDiscountedPrice(newPrice);
    try {
      await axios.post(`${config.INSCRIPTION_URL}/api/discounts/use`, {
        raceName: formattedRaceName,
        code: discountCode,
      });
      setMessage('Codigo de descuento aplicado');
      console.log("Uso del código registrado con éxito.");
    } catch (error) {
      console.error("Error al registrar el uso del código de descuento:", error);
    }
  } catch (error) {
    console.error("Error al validar el código de descuento:", error);
    if (error.response && error.response.status === 404) {
      alert("El código de descuento no es válido.");
    } else {
      alert("El código de descuento ya no está disponible.");
    }
  }
};

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleShirtSizeChange = (event) => {
    setShirtSize(event.target.value); // Maneja el cambio de tallaje
    console.log("Talle seleccionado:", event.target.value);
  };

  const handleShoeSizeChange = (event) => {
    setShoeSize(event.target.value); // Maneja el cambio de tallaje
    console.log("Talle seleccionado:", event.target.value);
  };
/** */
  const handleFormSubmit = async () => {
    const formId = uuidv4();
    const adjustedPriceTax = paymentOption === "1° cuota" ? (priceTax * 1.15) / 2 : priceTax;
    const isFirstInstallment = paymentOption === "1° cuota";
    const formData = {
      formId,
      FirstName: document.getElementById('first_name').value,
      LastName: document.getElementById('last_name').value,
      DNI: document.getElementById('document_number').value,
      gender,
      DateOfBirth: document.getElementById('birth_date').value,
      age: document.getElementById('age').value,
      email: document.getElementById('email').value,
      phone: document.getElementById('phone').value,
      Nacion: document.getElementById('Nation').value,
      Procedencia,
      talle: shirtSize,
      
      talleShoe: shoeSize,
      running_team: document.getElementById('Team').value,
      distance,
      price,
      discount_code: discountCode,
      discounted_price: discountedPrice || price,
      priceTax: adjustedPriceTax,
      paymentOption,
      payment_price: calculatePrice(),
      pending_price: isFirstInstallment ? Math.ceil(((discountedPrice || price) * 1.15) / 2000) * 1000 : 0,
      periodoInscripcion,
      raceName: formattedRaceName,
      contacto_emergencia: document.getElementById('contacto_emergencia').value,
      cel_emergencia: document.getElementById('cel_emergencia').value,
      obra_social: document.getElementById('obra_social').value,
      RH: document.getElementById('RH').value,
      peso: document.getElementById('peso').value,
      frecuencia_cardiaca: document.getElementById('frecuencia_cardiaca').value,
      medicamento: document.getElementById('medicamento').value,
      alergia: document.getElementById('alergia').value,
      enfermedad_cardiaca: document.getElementById('enfermedad_cardiaca').value,
      enfermedad_cronica: document.getElementById('enfermedad_cronica').value,
    };
    setEmail(formData.email); // Establece el correo electrónico para la preferencia
    try {
      /**await axios.post('http://localhost:5000/api/submitform', formData); */
      await axios.post(`${config.INSCRIPTION_URL}/submitform`, formData);
      console.log('Formulario enviado correctamente');
      return formId;
    } catch (error) {
      alert('Campos obligatorios')
      console.error('Error al enviar el formulario:', error);
      return null;
    }
  };

  const handleButtonClick = async () => {
    // Primero envía los datos del formulario
    const formId = await handleFormSubmit();
    // Luego crea la preferencia de pago
    if (formId) {
      const initPoint = await createPreference(formId);
    if (initPoint) {
      window.location.href = initPoint; // Redirige a la URL de pago de Mercado Pago
      }
    }
  };

  const calculatePrice = () => {
    const basePrice = discountedPrice || price;
    const totalPrice = basePrice + parseFloat(priceTax);
  
    if (paymentOption === "100%") {
      // Pago total (sin recargo)
      return totalPrice.toFixed(2);
    } else if (paymentOption === "1° cuota") {
      // Pago 50% con el 15% de recargo dividido en dos pagos
      const totalWithSurcharge = totalPrice * 1.15; // Añadir el 15% de recargo
      return (totalWithSurcharge / 2).toFixed(2); // Dividir en dos pagos iguales
    }
  
    return "0.00"; // Valor por defecto en caso de no coincidir
  };
  

  const handleProcedenciaChange = (event) => {
    setProcedencia(event.target.value);
  };
  

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: { xs: 2, md: 4},
        maxWidth: '100%',
        margin: 'auto',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      
      <Box
      sx={{
        backgroundColor: '#f5f5f5',
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Columna en pantallas pequeñas, fila en pantallas grandes
        justifyContent: 'center',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: { xs: 2, md: 4},
        borderRadius: 2,
        maxWidth: { xs: '100%', md: '80%' },
        fontFamily: 'Arial, sans-serif',
        boxShadow: "0px 1px 10px black",
        marginBottom: { xs: 2, md: 0 },
      }}
    >
      <Box
        component="img"
        src={image}
        alt="Banner"
        sx={{
          width: '100%',
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: '300px',
          boxShadow: "0px 1px 10px black",
        }}
      />
      <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1.5rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
        Datos del corredor
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="first_name"
            label="Nombre"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="last_name"
            label="Apellido"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="document_number"
            label="Número de Documento"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required variant="outlined">
            <InputLabel id="gender-label">Género</InputLabel>
            <Select
              id="gender"
              labelId="gender-label"
              value={gender}
              onChange={handleGenderChange}
              label="Género"
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#ccc',
                  },
                  '&:hover fieldset': {
                    borderColor: '#666',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1976d2',
                  },
                },
              }}
            >
              <MenuItem value="masculino">Masculino</MenuItem>
              <MenuItem value="femenino">Femenino</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="birth_date"
            label="Fecha de Nacimiento"
            variant="outlined"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="age"
            label="Edad al día de la carrera"
            variant="outlined"
            type="number"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="email"
            label="Correo Electrónico"
            variant="outlined"
            type="email"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="phone"
            label="Teléfono"
            variant="outlined"
            type="tel"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="Nation"
            label="Nacionalidad"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required variant="outlined">
            <InputLabel id="province-label">Provincia</InputLabel>
            <Select
              id="province"
              labelId="province-label"
              value={Procedencia}
              onChange={handleProcedenciaChange}
              label="Provincia"
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300, // Altura máxima de la lista desplegable
                    overflow: 'auto', // Habilita el desplazamiento
                  },
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#ccc',
                  },
                  '&:hover fieldset': {
                    borderColor: '#666',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1976d2',
                  },
                },
              }}
            >
              {[
                'Buenos Aires', 'CABA', 'Catamarca', 'Chaco', 'Chubut',
                'Córdoba', 'Corrientes', 'Entre Ríos', 'Formosa', 'Jujuy',
                'La Pampa', 'La Rioja', 'Mendoza', 'Misiones', 'Neuquén',
                'Río Negro', 'Salta', 'San Juan', 'San Luis', 'Santa Cruz',
                'Santa Fe', 'Santiago del Estero', 'Tierra del Fuego', 'Tucumán', 'Otra'
              ].map((province) => (
                <MenuItem key={province} value={province}>
                  {province}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
              <FormControl fullWidth required variant="outlined">
                <InputLabel id="shirt-size-label">Talle de remera</InputLabel>
                <Select
                  id='talle'
                  name="talle"
                  value={shirtSize}
                  onChange={handleShirtSizeChange}
                  label="Talle de remera"
                  required
                  sx={{
                    '& .MuiInputLabel-root': { color: '#333' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#ccc',
                      },
                      '&:hover fieldset': {
                        borderColor: '#666',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1976d2',
                      },
                    },
                  }}
                >
                  <MenuItem value="XS">XXS</MenuItem>
                  <MenuItem value="XS">XS</MenuItem>
                  <MenuItem value="S">S</MenuItem>
                  <MenuItem value="M">M</MenuItem>
                  <MenuItem value="L">L</MenuItem>
                  <MenuItem value="XL">XL</MenuItem>
                  <MenuItem value="XXL">XXL</MenuItem>
                  <MenuItem value="XXL">XXXL</MenuItem>
                </Select>
                <a
      href={talles}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textDecoration: 'none',
        color: 'red',
        fontSize: '0.875rem',
        display: 'inline-block',
        textAlign: 'left',
      }}
    >
      Tabla de talles
    </a>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required variant="outlined">
                <InputLabel id="shirt-size-label">Talle de zapatillas (AR)</InputLabel>
                <Select
                  id='tallezapas'
                  name="tallezapas"
                  value={shoeSize}
                  onChange={handleShoeSizeChange}
                  label="Talle de zapatillas"
                  required
                  sx={{
                    '& .MuiInputLabel-root': { color: '#333' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#ccc',
                      },
                      '&:hover fieldset': {
                        borderColor: '#666',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1976d2',
                      },
                    },
                  }}
                >
                  <MenuItem value="35/37">35/37</MenuItem>
                  <MenuItem value="38/40">38/40</MenuItem>
                  <MenuItem value="41/43">41/43</MenuItem>
                  <MenuItem value="44/46">44/46</MenuItem>
                </Select>
              </FormControl>
            </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="Team"
            label="Running Team"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="contacto_emergencia"
            label="Nombre contacto de emergencia"
            variant="outlined"
            required
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="cel_emergencia"
            label="Telefono de emergencia"
            variant="outlined"
            required
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom sx={{marginTop: 4, fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1.5rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
        Ficha medica
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="obra_social"
            label="Obra social"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="RH"
            label="Grupo y factor sanguíneo"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>  
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="peso"
            label="Peso"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>  
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="frecuencia_cardiaca"
            label="Frecuencia cardiaca"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>  
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="medicamento"
            label="Tomas algun medicamento de forma habitual?"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>  
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="alergia"
            label="Sos alérgico a algo?"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>  
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="enfermedad_cardiaca"
            label="Padeces alguna enfermedad cardiaca?"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>  
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="enfermedad_cronica"
            label="Padeces alguna enfermedad crónica?"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>  
        </Grid>     
      
    
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 8 }}>
            Código de Descuento
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="discountCode"
                label="Código"
                variant="outlined"
                value={discountCode}
                onChange={handleDiscountCodeChange}
                sx={{
                  '& .MuiInputLabel-root': { color: '#333' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ccc',
                    },
                    '&:hover fieldset': {
                      borderColor: '#666',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1976d2',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
            <Button
              fullWidth
              variant="contained"
              onClick={applyDiscount}
              sx={{
                height: '100%',
                backgroundColor: 'red', // Cambia el color de fondo a rojo
                color: 'white', // Cambia el color del texto a blanco
                '&:hover': {
                  backgroundColor: 'darkred', // Cambia el color al pasar el mouse
                },
              }}
            >
              Aplicar
            </Button>
            </Grid>
          </Grid>
          {message}
          
              <Typography variant="h5" gutterBottom sx={{ marginTop: 5, fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1.5rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
                Opciones de pago
              </Typography>
              <Typography variant="h8" gutterBottom sx={{ marginTop: 5 }}>
                Podes elegir PAGO TOTAL y abonar el 100% del valor de la inscripción o podes elegir PAGO EN DOS CUOTAS (se aplica un recargo del 15%) abonas la primera cuota ahora y la segunda cuota en EFECTIVO el día de la acreditación y retiro de Kit.
              </Typography>
          <FormControl fullWidth
          sx={{
            marginTop: 2,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#ccc',
              },
              '&:hover fieldset': {
                borderColor: '#666',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#1976d2',
              },
            },
          }}>
          <Select
            labelId="payment-option-label"
            value={paymentOption}
            onChange={(e) => setPaymentOption(e.target.value)}
          >
            <MenuItem value="100%">Pago Total</MenuItem>
            <MenuItem value="1° cuota">Pago en dos cuotas</MenuItem>
          </Select>
          </FormControl>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginTop: 4 }}>
                Información de la inscripción en {raceName}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {distance} - {periodoInscripcion}
              </Typography>

              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 2 }}>
                Precio {paymentOption}: ${paymentOption === "1° cuota" ? (((discountedPrice || price) * 1.15) / 2).toFixed(2) : (discountedPrice || price).toFixed(2)}
              </Typography>

              <Typography variant="h7" gutterBottom sx={{ fontWeight: 'bold' }}>
                Precio del servicio: ${paymentOption === "1° cuota" ? ((priceTax * 1.15) / 2).toFixed(2) : priceTax}
              </Typography>

              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 2 }}>
                Precio Total: $
                {paymentOption === "1° cuota"
                ? (((Number(discountedPrice) || Number(price)) * 1.15) / 2 + ((Number(priceTax || 0) / 2) * 1.15)).toFixed(2)
                : ((Number(discountedPrice) || Number(price)) + Number(priceTax || 0)).toFixed(2)}
              </Typography>


          <Typography variant="body2" gutterBottom sx={{ fontSize: '0.8rem' }}>
          {paymentOption} del valor total de la inscripción + comisión
          </Typography>
          <Button
            fullWidth
            variant="contained"
            onClick={handleButtonClick}
            sx={{
              marginTop: 3,
              backgroundColor: 'red', // Cambia el color de fondo a rojo
              color: 'white', // Cambia el color del texto a blanco
              '&:hover': {
                backgroundColor: 'darkred', // Cambia el color al pasar el mouse
              },
            }}
          >
            Finalizar
          </Button> 
          {preferenceId && (
            <Wallet
              initialization={{ preferenceId: preferenceId }}
              customization={{ texts: { valueProp: 'smart_option' } }}
            />
          )}
        </Box> 
      </Box>
    </Box>
  );
};

export default Forms;
