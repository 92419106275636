import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import TuTiempo from './components/TuTiempo';
import DataTable from './components/FinishRace/DataTable';
import Login from './components/DashBoard/Login';
import TopBar from './components/TopBar';
import ProxRace from './components/ProxRace';
import Nosotros from './components/Nosotros';
import Forms from './components/Inscriptions/Forms';
import NonoTrailRun from './components/FinishRace/NonoTrailRun';
import Inscripciones from './components/Inscriptions/Inscripciones';
import LoginUser from './components/Inscriptions/User/LoginUser';
import UserDash from './components/Inscriptions/User/UserDash';
import AuthVendedores from './components/Inscriptions/AuthVendedores';
import DesafioPuntaNegra2025 from './components/Inscriptions/Info/DesafioPuntaNegra2025/DesafioPuntaNegra2025';
import Misj2025 from './components/Inscriptions/Info/Misj2025/Misj2025';
import Forms02 from './components/Inscriptions/Info/Misj2025/Forms02'
import CalleAngosta2025 from './components/Inscriptions/Info/MaratonCalleAngosta2025/CalleAngosta2025';
import Forms03 from './components/Inscriptions/Info/MaratonCalleAngosta2025/Forms03';
import DeportesRioCuarto2025 from './components/Inscriptions/Info/DeportesRioCuarto2025/DeportesRioCuarto2025';
import Forms04 from './components/Inscriptions/Info/DeportesRioCuarto2025/Forms04'

function HeaderAndTopBar() {
  const location = useLocation();
  const isFormsRoute = /^\/[^/]+\/forms$/.test(location.pathname) || /^\/[^/]+\/forms02$/.test(location.pathname) || /^\/[^/]+\/forms03$/.test(location.pathname) || /^\/[^/]+\/forms04$/.test(location.pathname) || /^\/[^/]+\/userdash$/.test(location.pathname);
  const shouldShowTopBarAndHeader = !(
    ['/loginuser', '/userdash', '/nonotrailrun', '/authvendedores', '/desafiopuntanegra2025', '/maratoninternacionaldesanjuan2025', '/maratoncalleangosta2025', '/maratondeportesriocuarto2025']
      .includes(location.pathname) || 
    isFormsRoute
  );
  

  return shouldShowTopBarAndHeader ? (
    <>
      <TopBar />
      <Header />
    </>
  ) : null;
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <HeaderAndTopBar />
        <Routes>
          {/* Rutas sin TopBar y Header */}
          <Route exact path="/nonotrailrun" element={<NonoTrailRun/>}/>
          <Route exact path="/forms" element={<Forms/>} />
          <Route exact path="/forms02" element={<Forms/>} />
          <Route exact path="/:imageName" element={<DataTable/>}/>
          <Route exact path='/loginuser' element={<LoginUser/>}/>
          <Route exact path='/:raceName/userdash' element={<UserDash/>}/>
          <Route exact path='/authvendedores' element={<AuthVendedores/>}/>
          <Route exact path='/DesafioPuntaNegra2025' element={<DesafioPuntaNegra2025/>}/>
          <Route exact path='/MaratonInternacionalDeSanJuan2025' element={<Misj2025/>}/>
          <Route exact path='/:raceName/forms' element={<Forms/>}/>
          <Route exact path='/:raceName/forms02' element={<Forms02/>}/>
          <Route exact path='/MaratonCalleAngosta2025' element={<CalleAngosta2025/>}/>
          <Route exact path='/:raceName/forms03' element={<Forms03/>}/>
          <Route exact path='/MaratonDeportesRioCuarto2025' element={<DeportesRioCuarto2025/>}/>
          <Route exact path=':raceName/forms04' element={<Forms04/>}/>
          
          {/* Otras rutas */}
          <Route exact path="*" element={<Home/>}/>
          <Route exact path="/login" element={<Login/>}/>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/home" element={<Home/>}/>
          <Route exact path="/carreras" element={<ProxRace/>}/>
          <Route exact path="/tutiempo" element={<TuTiempo/>}/>
          <Route exact path="/Nosotros" element={<Nosotros />} />
          <Route exact path="/inscripciones" element={<Inscripciones/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
