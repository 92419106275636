import React, { useState, useEffect } from 'react';
import { Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import config from '../../../config/config.js';

const DownloadExcel = ({ raceName }) => {
  const [columns, setColumns] = useState([]); // Columnas disponibles
  const [selectedColumns, setSelectedColumns] = useState([]); // Columnas seleccionadas
  const [data, setData] = useState([]); // Datos de la tabla
  const [selectAll, setSelectAll] = useState(true);
  const [startDate, setStartDate] = useState(''); // Fecha inicial
  const [endDate, setEndDate] = useState(''); // Fecha final

  // Cargar los datos desde el backend
  useEffect(() => {
    const fetchData = async () => {
      if (!raceName) return;

      try {
        const response = await fetch(`${config.INSCRIPTION_URL}/api/download/${raceName}`);
        const jsonData = await response.json();

        if (jsonData.length > 0) {
          // Extraer columnas dinámicamente del primer objeto
          const dynamicColumns = Object.keys(jsonData[0]);
          setColumns(dynamicColumns);
          setSelectedColumns(dynamicColumns); // Seleccionar todas por defecto
        }
        setData(jsonData);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };

    fetchData();
  }, [raceName]);

  // Manejar cambios en la selección de columnas
  const handleColumnChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedColumns((prev) => [...prev, value]);
    } else {
      setSelectedColumns((prev) => prev.filter((column) => column !== value));
    }
  };

  // Manejar el botón "Seleccionar/Deseleccionar Todo"
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedColumns([]); // Deseleccionar todas
    } else {
      setSelectedColumns(columns); // Seleccionar todas
    }
    setSelectAll(!selectAll); // Alternar el estado
  };

  // Filtrar datos por rango de fechas usando "updatedAt"
  const filterByDate = (data) => {
    if (!startDate && !endDate) return data;

    return data.filter((row) => {
      const rowDate = new Date(row.updatedAt); // Usar "updatedAt" para filtrar
      const isAfterStartDate = startDate ? rowDate >= new Date(startDate) : true;
      const isBeforeEndDate = endDate ? rowDate <= new Date(endDate) : true;
      return isAfterStartDate && isBeforeEndDate;
    });
  };

  // Generar y descargar el archivo Excel
  const handleDownload = () => {
    if (data.length === 0) {
      alert('No hay datos disponibles para descargar.');
      return;
    }

    const filteredData = filterByDate(data).map((row) =>
      selectedColumns.reduce((obj, key) => {
        obj[key] = row[key];
        return obj;
      }, {})
    );

    if (filteredData.length === 0) {
      alert('No hay datos disponibles en el rango seleccionado.');
      return;
    }

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Inscriptos');

    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${currentDate.getFullYear()}`;

    const fileName = `Inscriptos_${raceName}_${formattedDate}.xlsx`;
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName);
  };

  return (
    <div className="container" style={{ marginTop: '70px' }}>
      <Typography variant="h5" gutterBottom sx={{marginBottom: 3 }}>Selecciona el rango de fechas y las columnas para descargar</Typography>
      <TextField
        label="Fecha de inicio"
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        sx={{ marginRight: 2 }}
      />
      <TextField
        label="Fecha de fin"
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
      />
      <br />
      <Button
        variant="contained"
        color="error"
        onClick={handleSelectAll}
        sx={{ marginTop: 5, marginBottom: '20px' }}
      >
        {selectAll ? 'Deseleccionar Todo' : 'Seleccionar Todo'}
      </Button>
      <br />
      {columns.map((column) => (
        <FormControlLabel
          key={column}
          control={
            <Checkbox
              checked={selectedColumns.includes(column)}
              onChange={handleColumnChange}
              value={column}
              color="error"
            />
          }
          label={column}
        />
      ))}
      <br />
      <Button
        variant="contained"
        color="error"
        onClick={handleDownload}
        sx={{ marginTop: '20px' }}
      >
        Descargar Excel
      </Button>
    </div>
  );
};

export default DownloadExcel;
