import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function Header({ raceName, toggleSidebar }) {
  return (
    <AppBar position="fixed" sx={{ bgcolor: '#f5f5f5', color: 'red' }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={toggleSidebar}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h5"  style={{ textAlign: 'left', textTransform: 'uppercase' }}>{raceName ? raceName.replace(/_/g, ' ') : 'No Data'}</Typography>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
